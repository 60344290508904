import * as routeUtils from "~/contentful/route-utils";

export const headers = routeUtils.headers;

export const handle = routeUtils.handle("page");

export const meta = routeUtils.meta;

export const loader = routeUtils.getContentfulPageLoader();

export default routeUtils.ContentfulPageComponent;

export const CatchBoundary = routeUtils.ContentfulCatchBoundary;

export const ErrorBoundary = routeUtils.ContentfulErrorBoundary;

export const shouldRevalidate = routeUtils.shouldRevalidate;
